<template>
    <div id="gallery-home" class="row" :class="{'gallery-on' : !!index}">
        <CoolLightBox
            :items="testimonials"
            :index="index"
            effect="fade"
            @close="index = null">
        </CoolLightBox>
        <section>
            <h2 data-aos="fade-up" class="title">Testimonials</h2>
            <div class="testimonial-top">
                <div class="testimonial-content-container">
                    <div class="testimonial-image-container">
                        <img data-aos="fade-in" data-aos-duration="1600"  class="testimonial-photo" src="/images/jeanie.webp" alt="Jeanie testimonial photo">
                    </div>
                    <div class="testimonial-details">
                        <div class="details-inner-container">
                            <h1 data-aos="fade-up" data-aos-delay="400" >
                                “A+... I could not recommend her more highly.”
                            </h1>
                            <h2 class="name" data-aos="fade-up" data-aos-delay="400" >
                                – Jeanie Buss
                            </h2>
                            <span data-aos="fade-up" data-aos-delay="400" >
                                Owner, Los Angeles Lakers
                                </span>
                                <button data-aos="fade-up" data-aos-delay="400"  @click="index = 0">
                                    <img src="/images/play-smaller.svg" alt="Play button">
                                    View video
                                </button>
                        </div>
                    </div>
                </div>
                <div class="testimonial-bg">
                    <!-- For background purpose-->
                </div>
            </div>
        </section>
        <section class="testimonials">
            <ul>
                <li v-for="(item, i) in  videos" :key="i" class="item video" data-aos="fade-up"  data-aos-duration="1000" data-aos-anchor-placement="top-bottom" :data-aos-delay="i % 3 * 400">
                    <section class="view">
                        <a href="#" @click.prevent="index=i+1" rel="vimeo" class=" play" title="Meredith Testimonial" target="_blank" aria-label="Click to open testimonial video in gallery">
                            <img class="play" src="/images/play_button.svg" alt="Testimonial Video">
                        </a>
                        <img :src="item.thumb" :alt="`Meredith Testimonial ${i+1}`">
                    </section>
                </li>
            </ul>
        </section>

        <div class="clear"></div>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
    name: "Testimonials",
    props:['numOfVideosShown'],
    components:{
        CoolLightBox
    },
    data(){
      return {
        index: null,
        testimonials: [
            {
                thumb: '/images/testimonials/home-testimonial-video-button-jeanie.jpg',
                src: 'https://vimeo.com/565592140'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-closer.jpg',
                src: 'https://vimeo.com/286090109'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-november-2021.jpg',
                src: 'https://vimeo.com/642334556'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-2.jpg',
                src: 'https://vimeo.com/321803601'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-3.jpg',
                src: 'https://vimeo.com/321803755'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-jordan.jpg',
                src: 'https://vimeo.com/286090283'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-rory.jpg',
                src: 'https://vimeo.com/581534377'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-1.jpg',
                src: 'https://vimeo.com/321803653'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-the-best.jpg',
                src: 'https://vimeo.com/286090427'
            },
            {
                thumb: '/images/testimonials/home-testimonial-video-button-apr.jpg',
                src: 'https://vimeo.com/332313194'
            },
            {
                thumb: '/images/testimonials/A.webp',
                src: 'https://vimeo.com/790139530'
            },
            {
                thumb: '/images/testimonials/B.webp',
                src: 'https://vimeo.com/790142974'
            },
            {
                thumb: '/images/testimonials/C.webp',
                src: 'https://vimeo.com/785208977'
            },
            {
                thumb: '/images/testimonials/video-testimonial-13.webp',
                src: 'https://vimeo.com/785208906'
            },

            {
                thumb: '/images/testimonials//video-testimonial-14.webp',
                src: 'https://vimeo.com/589997450'
            },
            {
                thumb: '/images/testimonials/video-testimonial-15.webp',
                src: 'https://vimeo.com/321803697'
            },
            {
                thumb: '/images/testimonials/video-testimonial-16.webp',
                src: 'https://vimeo.com/321803723'
            },
            {
                thumb: '/images/testimonials/video-testimonial-17.webp',
                src: 'https://vimeo.com/321837667'
            },
            {
                thumb: '/images/testimonials/video-testimonial-18.webp',
                src: 'https://vimeo.com/321803535'
            },
        ],
      }
    },
    computed:{
        videos(){
            return this.testimonials.slice(1, 1 + this.numOfVideosShown ? 1+this.numOfVideosShown : this.testimonials.length);
        }
    }
}
</script>


<style lang="scss" scoped>
#gallery-home{
    z-index: auto;
}
.title{
    margin-bottom: 90px;
}
.play{
    width: 60px;
}
.testimonial-top{
    position: relative;
    .testimonial-content-container{
        display: flex;
        position: relative;
        z-index: 11;
        justify-content: center;
        max-width: 1300px;
        margin: 0 auto;
    }
    .testimonial-bg{
        position: absolute;
        z-index: 10;
        background: #A9976E;
        width: 100%;
        height: 80vh;
        max-height: 434px;
        bottom: 0px;
    }
    .testimonial-photo{
        max-height: 459px;
        height: 80vh;
        display: block;
        max-width: 100%;
        width: auto;
    }
    .testimonial-details{
        display:flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        flex-direction: column;
        margin-top: 80px;
        h1{
            font-family: "EB Garamond", serif;
            font-size: 50px;
            line-height: 50px;
            text-transform: unset;
            max-width: 650px;
            padding-bottom: 20px;
        }
        .name{
            margin-top: 40px;
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: 30px;
            line-height: 35px;
        }
        span{
            font-family:"Oswald",Helvetica,Arial,sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 35px;
            color: #D9CFB8;
            text-transform: uppercase;
            display: block;
        }
        button{
            font-family:"Oswald",Helvetica,Arial,sans-serif;
            font-weight: 400;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 30px;
            margin-bottom: 40px;
            margin-left: auto;
            margin-right: auto;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 35px;
            font-weight: 400;
            padding: 10px 30px;
            color: #ffffff;
            width: unset;
            height: unset;
            border: none;
            background: rgba(0,0,0,0.5);
            &:hover{
                transition: 0.5s;
                background: rgba(0,0,0,1);
            }
        }
    }
}

.testimonials{
    position: relative;
    z-index: 10;
}

@media (max-width: 1100px) {
    .title{
        margin-bottom: 60px;
    }
    .testimonial-top{

        // background: #A9976E;
        .testimonial-bg{
            height: 80vh;
            max-height: 500px;
            top: 50px;
            bottom: unset;
        }
        .testimonial-photo{
            height: 100%;
            display: block;
            margin: 0 auto;
        }
        .testimonial-content-container{
            flex-direction: column;
        }
        .testimonial-details{
            margin: 0 auto;
            width: 100%;
            background: #988862;
            padding-top: 60px;
            .details-inner-container{
                width: 70%;
                margin: 0 auto;
            }
        }
    }
}
@media (max-width: $mobile) {
    .testimonial-top{
        .testimonial-photo{
            max-height: 459px;
            height: auto;
            width: 100%;
        }
    }
}
</style>

